<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">EUROPE TRAVEL INSURANCE</h1>
      </div>
      <div class="box b-shdw-3">
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

				<div class="content">
					<h2>Travel insurance for Europe</h2>
					<p class="mb-4">
						Europe is the most popular travel destination for UK holidaymakers. It is easily accessible and offers an abundance of history, a mixture of cultures, warmer climates, stunning landscapes, and beautiful beaches. Although it is not too far away, it is still important to have the right travel insurance in place before you travel. 
					</p>

					<h2>Do I need travel insurance if I am travelling to Europe?</h2>
					<p class="mb-4">
						Given that Europe isn’t too far to travel to, you may think that travel insurance might not be as important, compared to longer haul destinations. However, travel insurance for Europe is as important as anywhere else in the world. We need travel insurance to protect us for when the unexpected happens. Whether that be for, holiday cancellation, flight delays, lost luggage or stolen mobile phones. These things, unfortunately, do happen and travel insurance is there to give you the peace of mind, knowing that if something does go wrong, you have the right cover in place. 
					</p>

					<h2>Do I need travel insurance for Europe if I have an EHIC card?</h2>
					<p>
						An EHIC or GHIC covers state healthcare, not private treatment.
						<br>
						With an EHIC or GHIC you can get emergency or necessary medical care for the same cost as a resident in the country you’re visiting. This means that you can get healthcare at a reduced cost or for free.
						<br>
						An EHIC or GHIC is not a replacement for travel insurance – it does not cover everything, such as mountain rescue or being flown back to the UK (medical repatriation). Make sure you have both before you travel.
						<br>
						You’ll need to pay in full for treatment if you do not have an EHIC, GHIC or provisional replacement certificate (PRC).
						<br>
						The following European countries do not accept the EHIC or GHIC:
					</p>

					<ul class="mb-4">
						<li>the Channel Islands, including Guernsey, Alderney and Sark</li>
						<li>the Isle of Man</li>
						<li>Monaco</li>
						<li>San Marino</li>
						<li>the Vatican</li>
					</ul>

					<h2>What can European travel insurance cover?</h2>
					<p class="mb-4">
						<strong>Medical expenses</strong> – our policies provide cover for cost of emergency medical treatment abroad. We have three tiers of coverage available ranging from £5,000,000 to £10,000,000.
					</p>
					<p class="mb-4">
						<strong>Personal belongings</strong> – our policies will cover you for personal belongings / luggage, if it were to get lost, stolen, or damaged on your trip. Our policies also provide cover if you need to buy essentials to get you by, until your luggage is returned to you, following a delay.
					</p>
					<p class="mb-4">
            <strong>Holiday cancellation and delays</strong> – cover is provided if you needed to cancel your holiday due to you or anyone named on your policy, becoming ill, getting injured or being made redundant. Our policies also provide cover for transport delays. You can check the conditions for cancellation and delays in our
            <router-link :to="{name:'policyWordingsMUL'}">Policy Wordings</router-link>.
					</p>
					<p class="mb-4">
						<strong>Personal liability</strong> – Our policies provide cover for legal costs and liability for damages incurred by you which are caused by an accident during your trip, for circumstances such as accidental bodily injury to another person outside of your travelling party or damage to property which does not belong you.
					</p>

					<h2>Which countries does European travel insurance cover?</h2>
					<p class="mb-4">
						In the table below you will find all the countries we cover under ‘Europe’.
					</p>
					<div class="columns is-multiline area-container">
						<div class="column area-box is-2" v-for="area in getAreas()" :key="area.id">
							{{ area.country }}
						</div>
					</div>
					<br>

					<h2>Should I choose single trip or multi-trip travel insurance for a European trip?</h2>
					<p class="mb-4">
						If you are a frequent traveller you may find that annual cover will work out cheaper for you, rather than buying multiple single trips. If you have more than one trip to Europe booked, we would recommend you look at annual multi-trip travel insurance, to see which is more economical for you.  
					</p>

					<h2>Do I need business cover if I am travelling to Europe for work purposes?</h2>
					<p class="mb-4">
						If your work requires you to travel to Europe, then you may want to consider business travel insurance. This specific type of travel insurance will provide you with extra cover for work equipment, laptops / tablets, business money as well as your own personal belongings. Plus, the standard travel insurance benefits, such as, flight delays and cancellations. Business travel insurance is available to add to wherever you are travelling to in the world.
					</p>

					<p class="mb-4">
						<a href="https://www.covered2go.co.uk/get-quote">Get a quote</a> for Europe travel insurance today.
					</p>
					
				</div>
				
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
				<info-boxes/>
				<!-- <trustpilot-banner/> -->

			</div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from "../components/common/InfoBoxes";
//import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'EuropeTravelInsurance',
	data() {
		return {
			countriesCovered: null
		}
	},
	methods: {
		// Get an array of areas
		getAreas() {
			return this.countriesCovered.filter(a => {
					return a.area === 'Europe';
			}).sort((a, b) => {
				if(a.country < b.country) { return -1; }
				if(a.country > b.country) { return 1; }
				return 0;
			});
		}
	},
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	},
	created() {
		// Query API for travel areas
		this.$c2g.getTravelAreas().then((response) => {
				this.countriesCovered = JSON.parse(response.data);
		});
	}
};
</script>

<style lang="scss" scoped>
	@import 'src/styles/views/info-page';
	.area-box {
		border: 1px solid black;
		text-align: center;
		background-color: rgb(216, 214, 214);
		color: black;
	}
	.area-container {
		margin: 5px;
	}
</style>
